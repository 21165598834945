import { useWindowWidth } from '@react-hook/window-size'

import { useEquibar } from 'providers/Equibar'

const DEBOUNCE_TIME = 400

const MIN_WINDOW_SIZE_FOR_LAPTOP = 1152
const MAX_WINDOW_SIZE_FOR_MOBILE = 575

export const useIsLaptopWindowSize = () => {
  const { disableResponsive } = useEquibar()
  const windowWidth = useWindowWidth({ wait: DEBOUNCE_TIME })

  if (disableResponsive) {
    return true
  }

  return windowWidth >= MIN_WINDOW_SIZE_FOR_LAPTOP
}

export const useIsMobileWindowSize = () => {
  const { disableResponsive } = useEquibar()
  const windowWidth = useWindowWidth({ wait: DEBOUNCE_TIME })

  if (disableResponsive) {
    return false
  }

  return windowWidth <= MAX_WINDOW_SIZE_FOR_MOBILE
}
