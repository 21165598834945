const eqDSTheme = {
  borderRadius: {
    medium: '4px',
    small: '2px',
    none: 0,
  },
  boxShadow: {
    classic: '0 0 5px 0 rgba(0, 0, 0, 0.15)',
  },
  color: {
    // Primary
    primary: '#1a3640',
    primaryLight: '#334a53',
    secondary: '#d9d9d9',
    // Semantic
    background: '#f7f9fc',
    info: '#4580e6',
    success: '#3DCC91',
    error: '#FF7373',
    warning: '#EA9679',
    disabled: '#d9d9d9',
    // Neutral
    darkgrey: '#54636B',
    grey: '#8F8F8F',
    lightgrey: '#f0f0f0',
    white: '#ffffff',
    palette: {
      blue200: '#1F4B99',
      blue100: '#4580e6',
      blue50: '#91b8f5',
      blue10: '#edf3fc',
      gold100: '#F3A244',
      green100: '#3DCC91',
      green50: '#84f5c6',
      green10: '#eaf6f1',
      orange100: '#DC5D29',
      orange50: '#EA9679',
      pink100: '#cc3c90',
      pink50: '#f772c0',
      pink10: '#f6eaf1',
      purple100: '#9179F2',
      purple50: '#A3B0E4',
      red100: '#FF7373',
      yellow100: '#F7C246',
    },
  },
  fontSize: {
    xlarge: '20px',
    large: '16px',
    medium: '14px',
    small: '12px',
    xsmall: '10px',
  },
  fontWeight: {
    semiBold: '600',
  },
  space: {
    xlarge: '24px',
    large: '20px',
    medium: '16px',
    small: '12px',
    xsmall: '8px',
    xxsmall: '4px',
    xxxsmall: '2px',
    none: '0px',
    numeric: {
      small: 12,
      xsmall: 8,
      xxsmall: 4,
      xxxsmall: 2,
      none: 0,
    },
  },
  size: {
    xxlarge: '34px',
    xlarge: '24px',
    large: '20px',
    medium: '16px',
    small: '12px',
    xsmall: '8px',
    xxsmall: '4px',
    header: '64px',
    body: 'calc(100vh - 64px)',
  },
  transition: { classic: 'all 0.4s' },
  motionDuration: '0.1s',
  border: { classic: '1px solid' },
}

export default eqDSTheme
